<template>
  <div class="searchPanel">
    <div class="searchBar">
      <div class="searchBarInner">
        <div class="inputWrapper">
          <img class="searchIcon" src="@/assets/imgs/input-search.png" alt="" />
          <input ref="ipt" type="text" v-model="value" placeholder="请输入网点名称搜索" />
          <img
            v-if="showClear"
            @click="clrearValueClick"
            class="clearIcon"
            src="@/assets/imgs/input-clear.png"
            alt=""
          />
        </div>
        <div class="cancelBtn" @click="handleCancel">取消</div>
      </div>
    </div>
    <div class="searchResult">
      <ul class="list" v-if="filterList.length && value" :style="{ paddingTop: appBarHeight }">
        <li
          class="listItem"
          v-for="item in filterList"
          @click="handleListItemClick(item)"
          :key="item.id"
        >
          <div v-html="itemName(item)"></div>
        </li>
      </ul>
      <div
        class="noData"
        v-if="filterList.length === 0 && value && !isLoading"
        :style="{ paddingTop: appBarHeight }"
      >
        <img src="@/assets/imgs/no_data.png" alt="" />
        <span>无搜索结果</span>
      </div>
      <div class="history-list" v-if="!value.length">
        <div class="history-list-title">历史记录</div>
        <ul>
          <li
            v-for="item in historyList"
            @click="handleHistoryListItemClick(item)"
            :key="item.parkCode"
          >
            <div>{{ item.parkName }}</div>
            <div class="delete-history" @click.stop="deleteHistoryClick(item)">
              <img src="@/assets/imgs/StationManagement/History-delete@2x.png" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getAOIList, querySearchHistory, deleteHistoryById, addSearchHistory } from '@/api/api';
import { debounce } from '@/utils/index';
import bus from '@/utils/bus';

export default {
  props: {
    appBarHeight: {
      default: '0px',
    },
  },
  computed: {
    showClear() {
      return this.value !== '';
    },
  },
  data() {
    return {
      value: '',
      filterList: [], // 筛选列表
      historyList: [], // 搜索历史
      noData: false,
      isLoading: true,
      appBarHeightSession: (sessionStorage.getItem('_appBarHeight') ?? 0) + 0.1,
    };
  },
  mounted() {
    this.$refs.ipt.focus();
    this.getHistoryList();
    // 使用防抖调后端联想查询
    this.$refs.ipt.addEventListener('input', debounce(this.getParkList, 500));
  },
  methods: {
    clrearValueClick() {
      this.value = '';
      this.filterList = [];
    },
    handleCancel() {
      this.value = '';
      this.$router.back();
    },
    handleListItemClick(item) {
      addSearchHistory({
        parkCode: item.parkCode,
        source: 3,
        regionId: item.id,
      });
      bus.emit('onResetHighlightOverlay');
      bus.emit('onSelectPark', item);
      this.handleCancel();
    },
    handleHistoryListItemClick(item) {
      item.id = item.regionId;
      this.$store.commit('updateIsShowdetail', 0);
      bus.emit('onResetHighlightOverlay');
      bus.emit('onSelectPark', { ...item, fenceCenterPoint: [item.lng, item.lat] });
      this.handleCancel();
    },
    // 删除历史记录
    deleteHistoryClick(item) {
      deleteHistoryById(item.historyId).then(() => {
        this.historyList = this.historyList.filter((e) => e.parkCode !== item.parkCode);
        if (this.historyList.length === 0) {
          this.noData = true;
        }
      });
    },
    getParkList() {
      this.isLoading = true;
      getAOIList({ parkName: this.value })
        .then((list) => {
          this.filterList = list;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getHistoryList() {
      querySearchHistory({ source: 3 }).then((list) => {
        this.historyList = list;
      });
    },
    itemName(item) {
      let str = this.value;
      let replaceName = `<span class='hight-keyword'>${str}</span>`;
      var reg = new RegExp(str, 'g');
      let result = item.parkName.replace(reg, replaceName);
      let address = item.pro;
      if (item.pro !== item.city) {
        address += ' / ' + item.city;
      }
      address += ' / ' + result;
      return address;
    },
  },
};
</script>

<style lang="scss" scoped>
.searchPanel {
  width: 100vw;

  .searchBar {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 9;
    padding-top: var(--appBarHeight);

    .searchBarInner {
      width: 100%;
      padding: 8px 8px 13px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .inputWrapper {
      flex-grow: 1;
      height: 32px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #f6f7f8;
      border-radius: 4px;

      .searchIcon {
        width: 14px;
        height: 14px;
        margin-left: 9px;
        margin-right: 8px;
      }

      input {
        height: 32px;
        flex-grow: 1;
        font-size: 16px;
        color: #000000;
        caret-color: #436eff;
        background: #f6f7f8;

        &::placeholder {
          font-size: 14px;
          color: #bfc0cb;
          font-weight: normal;
        }
      }

      .clearIcon {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 7px;
        right: 8px;
      }
    }

    .cancelBtn {
      line-height: 32px;
      margin-left: 10px;
      font-size: 16px;
      color: #000000;
      height: 32px;
      width: max-content;
    }
  }

  .searchResult {
    margin-top: calc(32px + 13px + 8px + var(--appBarHeight));

    .list {
      .listItem {
        background: white;
        box-sizing: border-box;
        font-size: 16px;
        color: #000000;
        overflow: hidden;
        font-weight: 600;

        &:active {
          background: #f6f7f8;
        }

        div {
          margin: 0 20px;
          box-sizing: border-box;
          height: 54px;
          line-height: 54px;
          border-bottom: 1px solid #ebebeb;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .history-list {
      padding-top: 16px;
      .history-list-title {
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        line-height: 22px;
        padding-left: 17px;
      }
      ul li {
        position: relative;
        line-height: 54px;
        border-bottom: 1px solid #ebebeb;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        margin-left: 48px;
        padding-right: 29px;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 14px;
          height: 14px;
          left: -31px;
          background: url(../../../assets/imgs/StationManagement/History_Time@2x.png) no-repeat;
          background-size: 14px 14px;
        }
        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .delete-history {
          position: absolute;
          top: 0;
          right: 0;
          width: 42px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
    .noData {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 120px;

      img {
        width: 160px;
        height: 160px;
      }

      span {
        font-size: 16px;
        color: #222222;
      }
    }
  }
}
</style>
